import {
  AustraliaFlag,
  AustriaFlag,
  BelgiumFlag,
  BulgariaFlag,
  CanadaFlag,
  ChinaFlag,
  CroatiaFlag,
  CyprusFlag,
  CzechRepublicFlag,
  DenmarkFlag,
  EUFlag,
  FinlandFlag,
  FranceFlag,
  GermanyFlag,
  GreeceFlag,
  HungaryFlag,
  IndiaFlag,
  IrelandFlag,
  ItalyFlag,
  JapanFlag,
  KoreaFlag,
  LatviaFlag,
  LiechtensteinFlag,
  LuxembourgFlag,
  MalaysiaFlag,
  MaltaFlag,
  NetherlandsFlag,
  PolandFlag,
  PortugalFlag,
  RomaniaFlag,
  SingaporeFlag,
  SpainFlag,
  SwedenFlag,
  SwitzerlandFlag,
  UAEFlag,
  UKFlag,
  USAFlag,
} from '../../icons';

export function CountryFlag({ country, viewBox }: { country: string; viewBox?: string }) {
  switch (country.toLocaleLowerCase()) {
    case 'ca':
    case 'canada':
      return <CanadaFlag viewBox={viewBox} />;
    case 'us':
    case 'américain':
      return <USAFlag viewBox={viewBox} />;
    case 'roi':
    case 'ie':
      return <IrelandFlag viewBox={viewBox} />;
    case 'uk':
    case 'gb':
    case 'britannique':
      return <UKFlag viewBox={viewBox} />;
    case 'eu':
    case 'européen':
      return <EUFlag viewBox={viewBox} />;
    case 'uae':
      return <UAEFlag viewBox={viewBox} />;
    case 'india':
    case 'inde':
      return <IndiaFlag viewBox={viewBox} />;
    case 'japan':
    case 'japon':
      return <JapanFlag viewBox={viewBox} />;
    case 'malaysia':
    case 'malaisie':
      return <MalaysiaFlag viewBox={viewBox} />;
    case 'china':
    case 'chine':
      return <ChinaFlag viewBox={viewBox} />;
    case 'australia':
    case 'australie':
      return <AustraliaFlag viewBox={viewBox} />;
    case 'singapore':
    case 'singapour':
      return <SingaporeFlag viewBox={viewBox} />;
    case 'korea':
    case 'coréen':
      return <KoreaFlag viewBox={viewBox} />;
    case 'at':
      return <AustriaFlag viewBox={viewBox} />;
    case 'be':
      return <BelgiumFlag viewBox={viewBox} />;
    case 'bg':
      return <BulgariaFlag viewBox={viewBox} />;
    case 'hr':
      return <CroatiaFlag viewBox={viewBox} />;
    case 'cy':
      return <CyprusFlag viewBox={viewBox} />;
    case 'cz':
      return <CzechRepublicFlag viewBox={viewBox} />;
    case 'dk':
      return <DenmarkFlag viewBox={viewBox} />;
    case 'fi':
      return <FinlandFlag viewBox={viewBox} />;
    case 'fr':
      return <FranceFlag viewBox={viewBox} />;
    case 'de':
      return <GermanyFlag viewBox={viewBox} />;
    case 'gr':
      return <GreeceFlag viewBox={viewBox} />;
    case 'hu':
      return <HungaryFlag viewBox={viewBox} />;
    case 'it':
      return <ItalyFlag viewBox={viewBox} />;
    case 'lv':
      return <LatviaFlag viewBox={viewBox} />;
    case 'li':
      return <LiechtensteinFlag viewBox={viewBox} />;
    case 'lu':
      return <LuxembourgFlag viewBox={viewBox} />;
    case 'mt':
      return <MaltaFlag viewBox={viewBox} />;
    case 'nl':
      return <NetherlandsFlag viewBox={viewBox} />;
    case 'pl':
      return <PolandFlag viewBox={viewBox} />;
    case 'pt':
      return <PortugalFlag viewBox={viewBox} />;
    case 'ro':
      return <RomaniaFlag viewBox={viewBox} />;
    case 'es':
      return <SpainFlag viewBox={viewBox} />;
    case 'se':
      return <SwedenFlag viewBox={viewBox} />;
    case 'ch':
      return <SwitzerlandFlag viewBox={viewBox} />;
    default:
      return null;
  }
}

export default CountryFlag;
